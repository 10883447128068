import { useState } from "react";
import VerifyCodePage from "./VerifyCodePage";
import LoginPage from "./LoginPage";

export const LoginFlow = () => {
  const [waitingForCode, setWaitingForCode] = useState(false);

  return waitingForCode ? (
    <VerifyCodePage />
  ) : (
    <LoginPage waitforCode={() => setWaitingForCode(true)} />
  );
};

export default LoginFlow;
