/**
 * @generated SignedSource<<8932eb95222c59242fc5320651471e7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserContextViewerUserContextQuery$variables = {
  apiKey: string;
  userID: number;
};
export type UserContextViewerUserContextQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserContextViewer_userContext">;
};
export type UserContextViewerUserContextQuery = {
  response: UserContextViewerUserContextQuery$data;
  variables: UserContextViewerUserContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "apiKey"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v2 = [
  {
    "kind": "Variable",
    "name": "apiKey",
    "variableName": "apiKey"
  },
  {
    "kind": "Variable",
    "name": "userID",
    "variableName": "userID"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserContextViewerUserContextQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "UserContextViewer_userContext"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserContextViewerUserContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UserContext",
        "kind": "LinkedField",
        "name": "getUserContext",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "apiKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KVPair",
            "kind": "LinkedField",
            "name": "variables",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47b67b6f7845b055148e5fc1c00c09fb",
    "id": null,
    "metadata": {},
    "name": "UserContextViewerUserContextQuery",
    "operationKind": "query",
    "text": "query UserContextViewerUserContextQuery(\n  $userID: Int!\n  $apiKey: String!\n) {\n  ...UserContextViewer_userContext_JGR58\n}\n\nfragment UserContextViewerKVPairFragment_kv on KVPair {\n  key\n  value\n}\n\nfragment UserContextViewer_userContext_JGR58 on Query {\n  getUserContext(userID: $userID, apiKey: $apiKey) {\n    user {\n      apiKey\n      email\n      id\n      name\n    }\n    variables {\n      ...UserContextViewerKVPairFragment_kv\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "54f88428a564c3ad26519b69c4789def";

export default node;
