interface Config {
  apiUrl: string;
  aiUrl: string;
  gaiaUrl: string;
}

interface ConfigEnvironments {
  development: Config;
  staging: Config;
  production: Config;
  [key: string]: Config;
}

const environments: ConfigEnvironments = {
  development: {
    apiUrl: "https://api-staging.hellojoey.us/api/v11/app",
    aiUrl: "http://localhost:8080",
    gaiaUrl: "https://api-staging.hellojoey.us/api/era/v1",
  },
  staging: {
    apiUrl: "https://api-staging.hellojoey.us/api/v11/app",
    aiUrl: "https://api-dev.getera.app",
    gaiaUrl: "https://api-staging.hellojoey.us/api/era/v1",
  },
  production: {
    apiUrl: "https://api-prod.hellojoey.us/api/v11/app",
    aiUrl: "https://api-prod.getera.app",
    gaiaUrl: "https://api-prod.hellojoey.us/api/era/v1",
  },
};

function getConfig() {
  console.log("import.meta.env.MODE", import.meta.env.MODE);
  if (import.meta.env.MODE === "dev") {
    return environments.staging;
  } else if (import.meta.env.MODE === "prod") {
    return environments.production;
  }

  return environments.development;
}

const config: Config = getConfig();
console.log("config", config);

export default config;
