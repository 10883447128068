/**
 * @generated SignedSource<<0f50273de209685a3cd3a5009b12f17c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserContextViewerDeleteVariableMutation$variables = {
  apiKey: string;
  userID: number;
  variableName: string;
};
export type UserContextViewerDeleteVariableMutation$data = {
  readonly deleteUserContextVariable: boolean | null | undefined;
};
export type UserContextViewerDeleteVariableMutation = {
  response: UserContextViewerDeleteVariableMutation$data;
  variables: UserContextViewerDeleteVariableMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "apiKey"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userID"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "variableName"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "apiKey",
        "variableName": "apiKey"
      },
      {
        "kind": "Variable",
        "name": "userID",
        "variableName": "userID"
      },
      {
        "kind": "Variable",
        "name": "variableName",
        "variableName": "variableName"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteUserContextVariable",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserContextViewerDeleteVariableMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserContextViewerDeleteVariableMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "379e07062bc9f7fa1f942bcc1922d5cf",
    "id": null,
    "metadata": {},
    "name": "UserContextViewerDeleteVariableMutation",
    "operationKind": "mutation",
    "text": "mutation UserContextViewerDeleteVariableMutation(\n  $userID: Int!\n  $apiKey: String!\n  $variableName: String!\n) {\n  deleteUserContextVariable(userID: $userID, apiKey: $apiKey, variableName: $variableName)\n}\n"
  }
};
})();

(node as any).hash = "e00b6da54db4f7e4d17a42f7be013a6f";

export default node;
