export const types = ["OpenAI GPT", "Anthropic Claude"] as const;

export type ModelType = (typeof types)[number];

export interface Model<Type = string> {
  id: string;
  name: string;
  description: string;
  strengths?: string;
  type: Type;
}

export const models: Model<ModelType>[] = [
  {
    id: "c305f976-8e38-42b1-9fb7-d21b2e34f0da",
    name: "gpt-4",
    description:
      "Most capable model in the GPT series. Best for complex tasks that require a deep understanding of language.",
    type: "OpenAI GPT",
    strengths:
      "Language translation, complex classification, sentiment, summarization",
  },
  {
    id: "464a47c3-7ab5-44d7-b669-f9cb5a9e8465",
    name: "gpt-4-32k",
    description:
      "Most capable model in the GPT series. Best for complex tasks that require a deep understanding of language.",
    type: "OpenAI GPT",
    strengths:
      "Language translation, complex classification, sentiment, summarization",
  },
  {
    id: "ac0797b0-7e31-43b6-a494-da7e2ab43445",
    name: "gpt-3.5-turbo",
    description:
      "Capable of simple tasks, usually the fastest model in the GPT-3 series, and lowest cost.",
    type: "OpenAI GPT",
    strengths:
      "Parsing text, simple classification, address correction, keywords",
  },
  {
    id: "be638fb1-973b-4471-a49c-290325085802",
    name: "gpt-3.5-turbo-16k",
    description:
      "Capable of simple tasks, usually the fastest model in the GPT-3 series, and lowest cost.",
    type: "OpenAI GPT",
    strengths:
      "Parsing text, simple classification, address correction, keywords",
  },
  {
    id: "b43c0ea9-5ad4-456a-ae29-26cd77b6d0fb",
    name: "claude-2.0",
    description:
      "Anthropic's first model, capable of generating text in a variety of styles and genres.",
    type: "Anthropic Claude",
    strengths:
      "Language translation, Complex tasks, Sentiment analysis, Text generation, Summarization",
  },
  {
    id: "bbd57291-4622-4a21-9eed-dd6bd786fdd1",
    name: "claude-2.1",
    description:
      "Anthropic's second model, capable of generating text in a variety of styles and genres.",
    type: "Anthropic Claude",
    strengths:
      "Language translation, complex classification, sentiment analysis, improved accuracy, text summarization",
  },
  {
    id: "bbd57291-4622-4a21-9eed-dd6bd786fdd2",
    name: "claude-3-haiku-20240307",
    description:
      "The fastest, most cost-efficient AI model with multilingual and vision capabilities, optimized for instant responsiveness",
    type: "Anthropic Claude",
    strengths:
      "Language translation, vision capabilities, instant responsiveness, cost-efficient, quick analysis",
  },
  {
    id: "bbd57291-4622-4a21-9eed-dd6bd786fdd3",
    name: "claude-3-sonnet-20240229",
    description:
      "Balanced AI model offering strong performance, cost-efficiency, and enterprise scalability with vision capabilities ",
    type: "Anthropic Claude",
    strengths:
      "Language translation, vision capabilities, balanced performance, cost-effective, enterprise scalability",
  },
  {
    id: "bbd57291-4622-4a21-9eed-dd6bd786fdd4",
    name: "claude-3-opus-20240229",
    description:
      "The most powerful AI model for complex tasks, with top-level performance, intelligence, and human-like understanding",
    type: "Anthropic Claude",
    strengths:
      "Language translation, vision capabilities, complex tasks, top performance, human understanding",
  },
];
