import {
  PreloadedQuery,
  commitMutation,
  graphql,
  useFragment,
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from "react-relay";
import { User } from "../Playground";
import { UserContextViewerKVPairFragment_kv$key } from "./__generated__/UserContextViewerKVPairFragment_kv.graphql";
import { UserContextViewer_userContext$key } from "./__generated__/UserContextViewer_userContext.graphql";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { UserContextViewerUserContextQuery } from "./__generated__/UserContextViewerUserContextQuery.graphql";

const listSupportedVariableNames = [
  "globalContext",
  "pathwayChatHistory",
  "memory",
];

const kvPairFragment = graphql`
  fragment UserContextViewerKVPairFragment_kv on KVPair {
    key
    value
  }
`;

const kvPairDeleteUserContextVariableMutation = graphql`
  mutation UserContextViewerDeleteVariableMutation(
    $userID: Int!
    $apiKey: String!
    $variableName: String!
  ) {
    deleteUserContextVariable(
      userID: $userID
      apiKey: $apiKey
      variableName: $variableName
    )
  }
`;

const KVPairComponent = ({
  kvRef,
  onChange,
  clearVariable,
}: {
  kvRef: UserContextViewerKVPairFragment_kv$key;
  onChange: (key: string, value: string) => void;
  clearVariable: (variable: string) => void;
}) => {
  const data = useFragment(kvPairFragment, kvRef);

  useEffect(() => {
    if (data.key && data.value && onChange) {
      onChange(data.key, data.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <div className="p-5">
        <Label>{data.key}</Label>
      </div>
      <div className="p-5">
        <Button
          disabled={!listSupportedVariableNames.includes(data.key)}
          onClick={() => clearVariable(data.key)}
        >
          Clear
        </Button>
      </div>
      <div className="border-2 p-5">
        <Label>{data.value}</Label>
      </div>
    </>
  );
};

export const GQLUserContextFragment = graphql`
  fragment UserContextViewer_userContext on Query
  @argumentDefinitions(userID: { type: "Int!" }, apiKey: { type: "String!" }) {
    getUserContext(userID: $userID, apiKey: $apiKey) {
      user {
        apiKey
        email
        id
        name
      }
      variables {
        ...UserContextViewerKVPairFragment_kv
      }
    }
  }
`;

function UserContextViewerInternal({
  queryRef,
  onChange,
  clearVariable,
}: {
  user: User;
  queryRef: PreloadedQuery<
    UserContextViewerUserContextQuery,
    Record<string, unknown>
  >;
  onChange: (variables: Record<string, string>) => void;
  clearVariable: (variable: string) => void;
}) {
  const [userContextVariables, setUserContextVariables] = useState<
    Record<string, string>
  >({});
  const fragmentRef = usePreloadedQuery(
    GQLUserContextViewerUserContextQuery,
    queryRef
  );

  const data = useFragment<UserContextViewer_userContext$key>(
    GQLUserContextFragment,
    fragmentRef
  );

  useEffect(() => {
    if (onChange) {
      onChange(userContextVariables);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContextVariables]);

  const onChangeVariable = (key: string, value: string) => {
    setUserContextVariables((prev) => ({ ...prev, [key]: value }));
    // console.log("onChangeVariable", key, value);
  };

  return (
    <div className="w-full mt-10">
      <Separator className="mt-10 mb-10" />
      <Label className="font-bold">User Context Viewer</Label>
      <div className="mt-10 grid grid-cols-3 gap-5">
        <div className="p-5">
          <Label className="font-bold">Field Name</Label>
        </div>
        <div className="border-0 p-5">
          <Label className="font-bold">Action</Label>
        </div>
        <div className="border-0 p-5">
          <Label className="font-bold">Field Value</Label>
        </div>
        {data.getUserContext?.variables?.map((kvPair, index) => (
          <KVPairComponent
            key={index}
            kvRef={kvPair}
            onChange={onChangeVariable}
            clearVariable={clearVariable}
          />
        ))}
      </div>
    </div>
  );
}

export const GQLUserContextViewerUserContextQuery = graphql`
  query UserContextViewerUserContextQuery($userID: Int!, $apiKey: String!) {
    ...UserContextViewer_userContext
      @arguments(userID: $userID, apiKey: $apiKey)
  }
`;

function UserContextViewer({
  user,
  onChange,
}: {
  user: User;
  onChange: (variables: Record<string, string>) => void;
}) {
  const environment = useRelayEnvironment();
  const [userContextQueryRef, userContextLoadQuery] =
    useQueryLoader<UserContextViewerUserContextQuery>(
      GQLUserContextViewerUserContextQuery
    );

  useEffect(() => {
    if (user) {
      userContextLoadQuery({
        userID: +user.id,
        apiKey: user.apiKey,
      });
    }
  }, [userContextLoadQuery, user]);

  const clearVariable = (variable: string) => {
    commitMutation(environment, {
      mutation: kvPairDeleteUserContextVariableMutation,
      onCompleted: (response, errors) => {
        console.log(response);
        if (errors) {
          console.error(errors);
        } else {
          userContextLoadQuery(
            {
              userID: +user.id,
              apiKey: user.apiKey,
            },
            { fetchPolicy: "network-only" }
          );
        }
      },
      onError: (error) => console.error(error),
      variables: {
        userID: +user.id,
        apiKey: user.apiKey,
        variableName: variable,
      },
    });
  };

  return (
    <>
      {userContextQueryRef && (
        <UserContextViewerInternal
          user={user}
          queryRef={userContextQueryRef}
          onChange={onChange}
          clearVariable={clearVariable}
        />
      )}
    </>
  );
}

export default UserContextViewer;
