import axios from 'axios'
import config from '../../config'

export const runPrompt = async (input: string, model: string, apiKey: string): Promise<string> => {
  try {
    const response = await axios.post(
      `${config.aiUrl}/api/model/prompt`,
      { prompt: input, model },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`
        }
      }
    )
    return response.data.data
  } catch (error) {
    console.error('Error sending prompt to server:', error)
    throw new Error('Failed to process prompt')
  }
}

export const changeModelConfiguration = async (
  modelName: string,
  apiKey: string
): Promise<string> => {
  try {
    const response = await axios.post(
      `${config.aiUrl}/api/user/change-model`,
      { modelName, apiKey },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`
        }
      }
    )
    return response.data.message
  } catch (error) {
    console.error('Error changing model configuration:', error)
    throw new Error('Failed to change model configuration')
  }
}
