import { useSelector } from "react-redux";
import "./App.css";
import { RootState } from "./redux/store";
import AdminPage from "./AdminPage";
import LoginFlow from "./components/login/LoginFlow";
import { RelayEnvironmentProvider } from "react-relay";
import { createRelayEnvironment } from "./relay/environment";

function App() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );
  const user = useSelector((state: RootState) => state.auth.user);

  return isAuthenticated && user ? (
    <RelayEnvironmentProvider environment={createRelayEnvironment(user)}>
      <AdminPage />
    </RelayEnvironmentProvider>
  ) : (
    <LoginFlow />
  );
}

export default App;
