/**
 * @generated SignedSource<<271aa47265d596fe3a1683dde10724e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PromptManagerFragment$data = {
  readonly getModelResponse: string;
  readonly " $fragmentType": "PromptManagerFragment";
};
export type PromptManagerFragment$key = {
  readonly " $data"?: PromptManagerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PromptManagerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "model"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "prompt"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromptManagerFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "model",
          "variableName": "model"
        },
        {
          "kind": "Variable",
          "name": "prompt",
          "variableName": "prompt"
        }
      ],
      "kind": "ScalarField",
      "name": "getModelResponse",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "391b2cc43c5e895ae6c5ab1b6df1aa27";

export default node;
