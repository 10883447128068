/**
 * @generated SignedSource<<3abdabad6b27114d78e9d3b691d68a7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserContextViewerKVPairFragment_kv$data = {
  readonly key: string;
  readonly value: string;
  readonly " $fragmentType": "UserContextViewerKVPairFragment_kv";
};
export type UserContextViewerKVPairFragment_kv$key = {
  readonly " $data"?: UserContextViewerKVPairFragment_kv$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserContextViewerKVPairFragment_kv">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserContextViewerKVPairFragment_kv",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "KVPair",
  "abstractKey": null
};

(node as any).hash = "17cd049d8522ad51ff9772b4547f68fe";

export default node;
