import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type User } from '@/services/apis/Authentication'

interface AuthState {
  user: User | null
  isAuthenticated: boolean
  email: string
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  email: ''
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload
      state.isAuthenticated = !!action.payload
    },
    logout(state) {
      state.user = null
      state.isAuthenticated = false
    },
    setEmailStore(state, action: PayloadAction<string>) {
      state.email = action.payload
    }
  }
})

export const { setUser, logout, setEmailStore } = authSlice.actions
export default authSlice.reducer
