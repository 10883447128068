import { User } from "@/services/apis/Authentication";
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { RequestParameters } from "relay-runtime/lib/util/RelayConcreteNode";
import { Variables } from "relay-runtime/lib/util/RelayRuntimeTypes";
import config from "@/config";

export function createRelayEnvironment(user: User) {
  async function fetchGraphQL(
    operation: RequestParameters,
    variables: Variables
  ) {
    const response = await fetch(
      `${config.aiUrl}/graphql?${operation.operationKind}=${operation.name}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.jwt_token}`,
        },
        body: JSON.stringify({
          query: operation.text,
          variables,
        }),
      }
    );

    const data = await response.json();
    if (data.errors) {
      throw new Error(JSON.stringify(data.errors));
    }
    return data;
  }

  return new Environment({
    network: Network.create(fetchGraphQL),
    store: new Store(new RecordSource()),
    // log: (msg) => console.log(msg),
  });
}
