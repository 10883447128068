import * as React from "react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { graphql, usePaginationFragment } from "react-relay";
import { UserListGetPaginatedFilteredUsersRefetchableQuery } from "./__generated__/UserListGetPaginatedFilteredUsersRefetchableQuery.graphql";
import { UserListFragment$key } from "./__generated__/UserListFragment.graphql";
import { User } from "../Playground";
import { useEffect } from "react";
import { PlaygroundListUsersQuery$data } from "../__generated__/PlaygroundListUsersQuery.graphql";

// const data: User[] = [
//   {
//     id: "m5gr84i9",
//     name: "Test1",
//     email: "ken99@yahoo.com",
//     location: "New York",
//   },
//   {
//     id: "m5gr84i9",
//     name: "Test1",
//     email: "ken99@yahoo.com",
//     location: "New York",
//   },
//   {
//     id: "m5gr84i9",
//     name: "Test1",
//     email: "ken99@yahoo.com",
//     location: "New York",
//   },
//   {
//     id: "m5gr84i9",
//     name: "Test1",
//     email: "ken99@yahoo.com",
//     location: "New York",
//   },
// ];

const columns: ColumnDef<User>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <div className="capitalize">{row.getValue("name")}</div>,
  },
  {
    accessorKey: "email",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Email
          <CaretSortIcon className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="lowercase">{row.getValue("email")}</div>,
  },
  {
    accessorKey: "apiKey",
    header: () => <div className="text-right">API Key</div>,
    cell: ({ row }) => {
      //   const amount = parseFloat(row.getValue("amount"));

      // Format the amount as a dollar amount
      //   const formatted = new Intl.NumberFormat("en-US", {
      //     style: "currency",
      //     currency: "USD",
      //   }).format(amount);

      return (
        <div className="text-right font-medium">{row.getValue("apiKey")}</div>
      );
    },
  },
  //   {
  //     id: "actions",
  //     enableHiding: false,
  //     cell: ({ row }) => {
  //       const payment = row.original;

  //       return (
  //         <DropdownMenu>
  //           <DropdownMenuTrigger asChild>
  //             <Button variant="ghost" className="h-8 w-8 p-0">
  //               <span className="sr-only">Open menu</span>
  //               <DotsHorizontalIcon className="h-4 w-4" />
  //             </Button>
  //           </DropdownMenuTrigger>
  //           <DropdownMenuContent align="end">
  //             <DropdownMenuLabel>Actions</DropdownMenuLabel>
  //             <DropdownMenuItem
  //               onClick={() => navigator.clipboard.writeText(payment.id)}
  //             >
  //               Copy payment ID
  //             </DropdownMenuItem>
  //             <DropdownMenuSeparator />
  //             <DropdownMenuItem>View customer</DropdownMenuItem>
  //             <DropdownMenuItem>View payment details</DropdownMenuItem>
  //           </DropdownMenuContent>
  //         </DropdownMenu>
  //       );
  //     },
  //   },
];

const userListQuery = graphql`
  fragment UserListFragment on Query
  @refetchable(queryName: "UserListGetPaginatedFilteredUsersRefetchableQuery")
  @argumentDefinitions(
    first: { type: "Int", defaultValue: 10 }
    after: { type: "String" }
    search: { type: "String" }
  ) {
    getPaginatedFilteredUsers(first: $first, after: $after, search: $search)
      @connection(key: "UserList_getPaginatedFilteredUsers") {
      edges {
        node {
          id
          name
          email
          apiKey
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
        hasPreviousPage
      }
    }
  }
`;

function UserListView({
  users,
  hasNext,
  hasPrevious,
  handleNextPage,
  handlePreviousPage,
  selectUser,
}: {
  users: User[];
  hasNext: boolean;
  hasPrevious: boolean;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  selectUser: (user: User) => void;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  //   const [currentCursor, setCurrentCursor] = useState<string | null | undefined>(
  //     null
  //   );
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    selectUser(users[+Object.keys(rowSelection)[0]]);
  }, [rowSelection, selectUser, users]);

  const table = useReactTable<User>({
    data: users,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    enableMultiRowSelection: false,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={
              // () => table.previousPage()
              () => handlePreviousPage()
            }
            disabled={
              // !table.getCanPreviousPage()
              !hasPrevious
            }
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={
              // () => table.nextPage()
              () => handleNextPage()
            }
            disabled={
              // !table.getCanNextPage()
              !hasNext
            }
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export function UserList({
  queryRef,
  selectUser,
}: {
  queryRef: PlaygroundListUsersQuery$data;
  selectUser: (user: User) => void;
}) {
  const [pageNumber, setPageNumber] = React.useState(1);
  const { data, hasNext, hasPrevious, refetch } = usePaginationFragment<
    UserListGetPaginatedFilteredUsersRefetchableQuery,
    UserListFragment$key
  >(userListQuery, queryRef);

  const [search, setSearch] = React.useState("");

  const handleNextPage = () => {
    if (data.getPaginatedFilteredUsers?.pageInfo.hasNextPage) {
      const endCursor = data.getPaginatedFilteredUsers.pageInfo.endCursor;
      setPageNumber((oldpageNumber) => oldpageNumber + 1);
      // setCurrentCursor(endCursor);
      refetch({ first: 10, after: endCursor, search });
    }
  };

  const handlePreviousPage = () => {
    if (data.getPaginatedFilteredUsers?.pageInfo.hasPreviousPage) {
      setPageNumber((oldpageNumber) => oldpageNumber - 1);
      // Implement logic to get the previous page cursor
      // This requires your backend to support previous page cursors or managing cursor history
      const startCursor = data.getPaginatedFilteredUsers.pageInfo.startCursor;
      // setCurrentCursor(startCursor);
      refetch({ first: 10, after: startCursor, search });
    }
  };

  const handleSearch = () => {
    refetch({
      search,
      first: 10,
      after: null,
    });
  };

  const users: User[] =
    data.getPaginatedFilteredUsers?.edges
      .slice((pageNumber - 1) * 10, pageNumber * 10)
      .map(
        (edge) =>
          edge?.node ?? {
            id: "1",
            name: "Test",
            email: "",
            apiKey: "",
          }
      ) ?? ([] as User[]);
  // console.log("UserList", users);

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Search..."
          value={search}
          onChange={(event) =>
            // table.getColumn("email")?.setFilterValue(event.target.value)
            setSearch(event.target.value)
          }
          className="max-w-sm"
        />
        <Button className="w-24 ml-5" onClick={handleSearch}>
          Search
        </Button>
      </div>
      <UserListView
        users={users}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        handlePreviousPage={handlePreviousPage}
        handleNextPage={handleNextPage}
        selectUser={selectUser}
      />
    </div>
  );
}
