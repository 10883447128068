import { combineReducers, configureStore, Middleware } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import modelReducer from './slices/modelSlice'
import { setupListeners } from '@reduxjs/toolkit/query'

const localStorageMiddleware: Middleware = store => next => action => {
  const result = next(action);
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
  return result;
};

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('reduxState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const preloadedState = loadState();

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    model: modelReducer
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(localStorageMiddleware),
  preloadedState
})

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
