/**
 * @generated SignedSource<<efdb198e1c690316d886e9115e7ae870>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaygroundListUsersQuery$variables = Record<PropertyKey, never>;
export type PlaygroundListUsersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserListFragment">;
};
export type PlaygroundListUsersQuery = {
  response: PlaygroundListUsersQuery$data;
  variables: PlaygroundListUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaygroundListUsersQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UserListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PlaygroundListUsersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "getPaginatedFilteredUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "apiKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "getPaginatedFilteredUsers(first:10)"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "search"
        ],
        "handle": "connection",
        "key": "UserList_getPaginatedFilteredUsers",
        "kind": "LinkedHandle",
        "name": "getPaginatedFilteredUsers"
      }
    ]
  },
  "params": {
    "cacheID": "a018b81ada498753d9dfdd5a0d4b21bf",
    "id": null,
    "metadata": {},
    "name": "PlaygroundListUsersQuery",
    "operationKind": "query",
    "text": "query PlaygroundListUsersQuery {\n  ...UserListFragment\n}\n\nfragment UserListFragment on Query {\n  getPaginatedFilteredUsers(first: 10) {\n    edges {\n      node {\n        id\n        name\n        email\n        apiKey\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n      startCursor\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d3cb2d4e1a7bd0f11f0f6dc59d654c6a";

export default node;
