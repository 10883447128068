import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface ModelDetails {
  selectedModel: string
  temperature?: number
}

const initialState: ModelDetails = {
  selectedModel: 'GPT-4',
  temperature: 0.7
}

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setModel(state, action: PayloadAction<{ selectedModel: string; temperature: number }>) {
      state.selectedModel = action.payload.selectedModel
      state.temperature = action.payload.temperature
    }
  }
})

export const { setModel } = modelSlice.actions
export default modelSlice.reducer
