import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Suspense, useState } from "react";
import {
  PreloadedQuery,
  UseQueryLoaderLoadQueryOptions,
  graphql,
  useFragment,
  usePreloadedQuery,
} from "react-relay";
import {
  PlaygroundPromptManagerQuery,
  PlaygroundPromptManagerQuery$variables,
} from "../__generated__/PlaygroundPromptManagerQuery.graphql";
import { GQLPlaygroundPromptManagerQuery } from "../Playground";
import { PromptManagerFragment$key } from "./__generated__/PromptManagerFragment.graphql";

const GQLPromptManagerFragment = graphql`
  fragment PromptManagerFragment on Query
  @argumentDefinitions(
    prompt: { type: "String!" }
    model: { type: "String!" }
  ) {
    getModelResponse(prompt: $prompt, model: $model)
  }
`;

function PromptManagerModelResponse({
  queryRef,
}: {
  queryRef: PreloadedQuery<
    PlaygroundPromptManagerQuery,
    Record<string, unknown>
  >;
}) {
  const fragmentRef = usePreloadedQuery(
    GQLPlaygroundPromptManagerQuery,
    queryRef
  );
  const data = useFragment<PromptManagerFragment$key>(
    GQLPromptManagerFragment,
    fragmentRef
  );

  return (
    <Textarea
      disabled
      value={data.getModelResponse}
      placeholder="Model response will appear here."
      className="h-full min-h-[300px] lg:min-h-[400px] xl:min-h-[400px]"
    />
  );
}

function PromptManager({
  variables,
  queryRef,
  loadQuery,
}: {
  variables: Record<string, string>;
  queryRef:
    | PreloadedQuery<PlaygroundPromptManagerQuery, Record<string, unknown>>
    | undefined
    | null;
  loadQuery: (
    variables: PlaygroundPromptManagerQuery$variables,
    options?: UseQueryLoaderLoadQueryOptions | undefined
  ) => void;
}) {
  const [prompt, setPrompt] = useState<string>("");
  const completedPrompt = prompt.replace(/{([a-zA-Z]+)}/g, (m, i) =>
    i in variables ? variables[i] : m
  );

  const submitQuery = () => {
    if (completedPrompt) {
      loadQuery({ prompt: completedPrompt, model: "gpt-4" });
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="grid h-fit grid-rows-2 gap-6 lg:grid-cols-2 lg:grid-rows-1">
        <Textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Write your prompt here!"
          className="h-fit min-h-[200px] lg:min-h-[300px] xl:min-h-[300px]"
        />

        {queryRef ? (
          <Suspense
            fallback={
              <Textarea
                disabled
                // value={}
                placeholder="Getting response from model..."
                className="h-fit min-h-[200px] lg:min-h-[300px] xl:min-h-[300px]"
              />
            }
          >
            <PromptManagerModelResponse queryRef={queryRef} />
          </Suspense>
        ) : (
          <Textarea
            disabled
            // value={}
            placeholder="Model response will appear here."
            className="h-fit min-h-[200px] lg:min-h-[300px] xl:min-h-[300px]"
          />
        )}
        <Textarea
          disabled
          value={completedPrompt}
          onChange={() => {}}
          placeholder="Completed prompt will appear here."
          className="h-fit min-h-[200px] lg:min-h-[300px] lg:col-span-2 xl:min-h-[300px]"
        />
        {/* <div className="rounded-md border bg-muted"></div> */}
      </div>
      <div className="flex items-center space-x-2">
        <Button onClick={submitQuery}>Submit</Button>
        {/* <Button variant="secondary">
                        <span className="sr-only">Show history</span>
                        <CounterClockwiseClockIcon className="h-4 w-4" />
                      </Button> */}
      </div>
    </div>
  );
}

export default PromptManager;
