import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/slices/authSlice";
import { type RootState } from "../../redux/store";
import { checkAdmin, verifyEmail } from "../../services/apis/Authentication";
import "./VerifyCodePage.css";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";

export const VerifyCodePage = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.auth.email);

  const handleVerifyCode = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      const user = await verifyEmail(email, code);
      const hasAccess = await checkAdmin(user.email);
      if (hasAccess) {
        console.log("User verified:", user);
        dispatch(setUser(user));
        // navigate('/admin')
      } else {
        console.error("Access permissinnnon failed:", error);
        setError("User does not have access to the admin page.");
      }
    } catch (error) {
      console.error("Verification failed:", error);
      setError("Verification failed. Please check your code.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app-container">
      <div className="form-container">
        <form onSubmit={handleVerifyCode}>
          <Label>
            Verification Code:
            <Input
              type="text"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              required
            />
          </Label>
          <Button type="submit" disabled={loading}>
            Verify Code
          </Button>
          {error && <div>{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default VerifyCodePage;
