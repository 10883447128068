import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import config from "@/config";

const Tools: React.FC = () => {
  const handlePromptlayerResetCache = () => {
    fetch(`${config.aiUrl}/prompt/cache-clean`, {
      method: "POST",
    })
      .then((res) => {
        if (res.ok) {
          alert("Promptlayer cache has been reset.");
        } else {
          alert("Failed to reset Promptlayer cache.");
        }
      })
      .catch((e) => {
        alert("Failed to reset Promptlayer cache. Error:" + e);
      });
  };

  const handleContentfulResetCache = () => {
    fetch(`${config.aiUrl}/contentful/cache-clean`, {
      method: "POST",
    })
      .then((res) => {
        if (res.ok) {
          alert("Contentful cache has been reset.");
        } else {
          alert("Failed to reset Contentful cache.");
        }
      })
      .catch((e) => {
        alert("Failed to reset Contentful cache. Error:" + e);
      });
  };

  return (
    <div className="max-h-screen mt-20">
      <Label>Tools for specific tasks.</Label>
      <div className="w-auto mt-10 grid grid-cols-2 gap-5">
        <Button onClick={handlePromptlayerResetCache}>
          Reset Promptlayer Cache
        </Button>
        <Button onClick={handleContentfulResetCache}>
          Reset Contentful Cache
        </Button>
      </div>
    </div>
  );
};

export default Tools;
