import axios from "axios";
import config from "../../config";

export interface User {
  id: string;
  uuid: string;
  jwt_token: string;
  name: string;
  last_active_at: string;
  api_key: string;
  avatar_url: string;
  check_in_counts: number;
  setting: Settings;
  email: string;
  subscription: Subscription;
  kids: Kid[];
  user_onboarding_info: OnboardingInfo;
}

export interface Kid {
  id: number;
  birth_date?: string;
  birth_day: number;
  birth_month: number;
  birth_year: number;
  name: string;
  color: string;
  age: number;
  uuid?: string;
  connect_wheel?: "disabled" | "enabled";
}

export interface Settings {
  default_kid_uuid_for_playlist?: string | null;
  appsflyer_id?: string;
  daily_path_completed_count?: number;
}

interface OnboardingInfo {
  data: {
    onboarding_focus: string | null;
    onboarding_topics: string[];
    onboarding_intention: string | null;
  };
}

interface Subscription {
  id: string;
  user_id: string;
  state: string;
  available: boolean;
}

export interface UserProfile {
  name: string;
  email: string;
}

export interface ChatMessage {
  id?: number;
  era_api_id: string;
  journey_id?: string;
  timestamp: string;
  text_content: string;
  sender: Sender;
}

export enum JourneyStates {
  Question,
  Initial,
  Continue,
  Ending,
}

export enum Sender {
  AI = "ai",
  USER = "user",
}

interface InteractionData {
  timestamp: Date;
  method: string;
  path: string;
  requestBody: string;
  responseBody: string;
}

interface Interaction {
  interactionKey: string;
  interactionData: InteractionData;
}

interface InteractionInfo {
  timestamp: string;
  promptSent: string;
  promptReceived: string;
  promptName: string;
  PromptLayerId: string;
  userId: string;
}

export const getUserProfileGaia = async (
  apiKey: string
): Promise<UserProfile> => {
  try {
    const response = await axios.get<UserProfile>(
      `${config.gaiaUrl}/gaia/v1/users/profile`,
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting User profile:", error);
    throw error;
  }
};

export const getUserProfileRedis = async (
  apiKey: string,
  redisUserProfile: string
): Promise<UserProfile> => {
  try {
    const response = await axios.get<UserProfile>(
      `${config.aiUrl}/api/redis/${apiKey}/${redisUserProfile}`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting User profile:", error);
    throw error;
  }
};

export const getInteractions = async (
  apiKey: string
): Promise<Interaction[]> => {
  try {
    const response = await axios.get<{ interactions: Interaction[] }>(
      `${config.aiUrl}/api/interactions/${apiKey}`
    );
    console.log("received");
    console.log(response);
    const interactions = response.data.interactions.map((interaction) => ({
      interactionKey: interaction.interactionKey,
      interactionData: interaction.interactionData,
    }));
    console.log("sent");
    console.log(interactions);
    return interactions;
  } catch (error) {
    console.error("Error getting interactions:", error);
    throw error;
  }
};

export const getInteractionsInfo = async (
  email: string
): Promise<InteractionInfo[]> => {
  try {
    const response = await axios.get<{ interactionsInfo: InteractionInfo[] }>(
      `${config.aiUrl}/api/interactionsInfo/${email}`
    );

    const interactionsInfo = response.data.interactionsInfo.map(
      (interactionInfo) => ({
        timestamp: interactionInfo.timestamp,
        promptSent: interactionInfo.promptSent,
        promptReceived: interactionInfo.promptReceived,
        promptName: interactionInfo.promptName,
        PromptLayerId: interactionInfo.PromptLayerId,
        userId: interactionInfo.userId,
      })
    );
    return interactionsInfo;
  } catch (error) {
    console.error("Error getting interactions:", error);
    throw error;
  }
};

export const clearInteractions = async () => {
  try {
    const response = await axios.post<JourneyStates>(
      `${config.aiUrl}/api/interactions/cache-clean`
    );
    return response.data;
  } catch (error) {
    console.error("Error cleaning interactions:", error);
    throw error;
  }
};

export const clearInteractionsInfo = async () => {
  try {
    const response = await axios.post<JourneyStates>(
      `${config.aiUrl}/api/interactionsInfo/cache-clean`
    );
    return response.data;
  } catch (error) {
    console.error("Error cleaning interactionsInfo:", error);
    throw error;
  }
};

export const getUserStateRedis = async (
  apiKey: string,
  redisUserState: string
): Promise<JourneyStates> => {
  try {
    const response = await axios.get<JourneyStates>(
      `${config.aiUrl}/api/redis/${apiKey}/${redisUserState}`
    );
    return response.data;
  } catch (error) {
    console.error("Error getting User profile:", error);
    throw error;
  }
};

export const requestVerification = async (
  email: string
): Promise<UserProfile> => {
  const response = await fetch(config.apiUrl + "/request_verification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Error fetching the verification token");
  }
  console.log(data);
  return data.token;
};

export const verifyEmail = async (
  email: string,
  code: string
): Promise<User> => {
  const response = await fetch(config.gaiaUrl + "/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, verification_code: code }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Error fetching the verification token");
  }
  console.log(data);
  return data;
};

export const checkAdmin = async (email: string): Promise<boolean> => {
  try {
    console.log(email);
    console.log(`${config.aiUrl}/api/isAdmin/${email}`);
    const response = await axios.get<{ isAdmin: boolean }>(
      `${config.aiUrl}/api/isAdmin/${email}`
    );
    return response.data.isAdmin;
  } catch (error) {
    console.error("Error checking admin status:", error);
    return false;
  }
};
