import React, { useState, type FormEvent } from "react";
import { changeModelConfiguration } from "../../../services/apis/Model";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import "./SectionsStyles.css";

interface ModelSelectorProps {
  apiKey: string;
}

const ModelSelector: React.FC<ModelSelectorProps> = ({ apiKey }) => {
  const [model, setModel] = useState("");

  // const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
  //   setModel(e.target.value);
  // };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const message = await changeModelConfiguration(
        model === "none" ? "" : model,
        apiKey
      );
      alert(message);
    } catch (error) {
      if (error instanceof Error) {
        alert("Error: " + error.message);
      } else {
        alert("An unexpected error occurred");
      }
    }
  };

  // claude-3-opus-20240229	claude-3-sonnet-20240229	claude-3-haiku-20240307
  // claude-2.1	claude-2.0	claude-instant-1.2
  // gpt-4 (8k token)
  // gpt-4-32k (limited beta)
  // gpt-3.5-turbo (default model 4k token)
  // gpt-3.5-turbo-16k
  return (
    <form onSubmit={handleSubmit} className="formContainer">
      {/* <select value={model} onChange={handleChange} className="select">
        <option value="">Select a model</option>
        <option value="gpt-4">GPT-4</option>
        <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
        <option value="claude-3-haiku-20240307">Claude-3</option>
        <option value="claude-2.1">Claude-2.5</option>
      </select> */}
      <div className="mb-10 w-full">
        <Select
          value={model}
          onValueChange={(value) => setModel(value)}
          required
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a model" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">Reset</SelectItem>
            <SelectItem value="gpt-4">GPT-4</SelectItem>
            <SelectItem value="gpt-3.5-turbo">GPT-3.5 Turbo</SelectItem>
            <SelectItem value="claude-3-haiku-20240307">Claude-3</SelectItem>
            <SelectItem value="claude-2.1">Claude-2.5</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <button type="submit" className="button">
        Change Model
      </button>
    </form>
  );
};

export default ModelSelector;
