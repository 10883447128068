import { Label } from "@/components/ui/label";
import ModelSelector from "./ModelSelector";

const Admin: React.FC<{ apiKey: string }> = ({ apiKey }) => {
  return (
    <div className="max-h-screen mt-20">
      <Label>Change AI model for the current user.</Label>
      <ModelSelector apiKey={apiKey}></ModelSelector>
    </div>
  );
};

export default Admin;
