import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "../src/redux/store";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { logout } from "./redux/slices/authSlice";
import { Separator } from "@/components/ui/separator";
import PlaygroundPage from "./components/models/playground/Playground";
import Tools from "./components/models/tools/Tools";
import Admin from "./components/models/admin/Admin";

export enum Tab {
  Admin = "admin",
  Playground = "playground",
  Tools = "tools",
}

const TabContent: React.FC<{ tab: Tab; apiKey: string }> = ({
  tab,
  apiKey,
}) => {
  switch (tab) {
    case Tab.Admin:
      return <Admin apiKey={apiKey} />;
    case Tab.Playground:
      return <PlaygroundPage />;
    case Tab.Tools:
      return <Tools />;
  }
};

export const AdminPage: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const [tab, setTab] = React.useState<Tab>(Tab.Admin);
  let apiKey = "";
  if (user) {
    apiKey = user.api_key;
  }

  return (
    <div>
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle()}
              onClick={() => {
                setTab(Tab.Admin);
              }}
            >
              Admin
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle()}
              onClick={() => {
                setTab(Tab.Playground);
              }}
            >
              Playground
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle()}
              onClick={() => {
                setTab(Tab.Tools);
              }}
            >
              Tools
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle()}
              onClick={() => dispatch(logout())}
            >
              Logout
            </NavigationMenuLink>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
      <div>
        <Separator />
        <TabContent tab={tab} apiKey={apiKey} />
      </div>
    </div>
  );
};

export default AdminPage;
