/**
 * @generated SignedSource<<296ed20869a083880cb3b7418317c532>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlaygroundPromptManagerQuery$variables = {
  model: string;
  prompt: string;
};
export type PlaygroundPromptManagerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PromptManagerFragment">;
};
export type PlaygroundPromptManagerQuery = {
  response: PlaygroundPromptManagerQuery$data;
  variables: PlaygroundPromptManagerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "model"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prompt"
},
v2 = [
  {
    "kind": "Variable",
    "name": "model",
    "variableName": "model"
  },
  {
    "kind": "Variable",
    "name": "prompt",
    "variableName": "prompt"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaygroundPromptManagerQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "PromptManagerFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlaygroundPromptManagerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "kind": "ScalarField",
        "name": "getModelResponse",
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1cc200bd00408c2870ffb84b1cd8af30",
    "id": null,
    "metadata": {},
    "name": "PlaygroundPromptManagerQuery",
    "operationKind": "query",
    "text": "query PlaygroundPromptManagerQuery(\n  $prompt: String!\n  $model: String!\n) {\n  ...PromptManagerFragment_3TUnJQ\n}\n\nfragment PromptManagerFragment_3TUnJQ on Query {\n  getModelResponse(prompt: $prompt, model: $model)\n}\n"
  }
};
})();

(node as any).hash = "4624570c7d36227def77ca2446078e1d";

export default node;
